<template>
  <b-card-code title="">
    <b-button style="margin-top: -60px; float: right" variant="primary" @click="showAddModal">إضافة فاتورة</b-button>
    <!-- <VueFileUploud /> -->

    <b-row align-h="between">
      <b-col md="5">
        <b-row>
          <b-col>
            <b-form-group label="الشركة">
              <v-select v-model="selectedCompany" :options="companies" :getOptionLabel="(comp) => comp.userDisplayName" dir="rtl"></v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="رقم الفاتورة">
              <b-form-input v-model="invoiceNumber" dir="rtl"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="7" align-self="center" class="">
        <b-row>
          <b-col md="4">
            <b-form-group label="تاريخ البداية">
              <b-form-datepicker
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="ar"
                size="lg"
                v-model="startDate"
                placeholder=""
                :rtl="direction"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="تاريخ النهاية">
              <b-form-datepicker
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="ar"
                size="lg"
                v-model="endDate"
                placeholder=""
                :rtl="direction"
              />
            </b-form-group>
          </b-col>

          <b-col align-self="center" class="d-flex justify-content-start mt-1">
            <b-button variant="primary" @click="getInvoicesFromGrid">
              <feather-icon icon="SearchIcon" size="16" />
            </b-button>
          </b-col>
          <b-col align-self="center" class="d-flex justify-content-start mt-1">
            <b-form-group>
              <b-form-checkbox class="mt-1" v-model="watermark" switch inline>
                <!-- add two type -->
                <span>بغلاف</span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-skeleton-table v-if="loading" :rows="2" :columns="9" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>

    <vue-good-table
      v-else
      :columns="columns"
      :rows="this.invoices"
      :rtl="direction"
      :lineNumbers="true"
      :paginationOptions="{
        enabled: true,
        perPage: pageSize
      }"
      :sortOptions="{
        enabled: true
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'noticeItemsTotal'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'invoiceItemsTotal'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'unTaxInvoiceItemsTotal'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'invoiceTax14Total'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>

        <span v-else-if="props.column.field === 'invoiceTax10Total'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>

        <span v-else-if="props.column.field === 'invoiceTaxTransport'">
          {{ formatCurrency(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'invoiceStatus'">
          <b-badge :variant="badgeVariant(props.formattedRow[props.column.field])" class="text-capitalize">
            {{ badgeText(props.formattedRow[props.column.field]) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'date'">
          {{ formatDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'action'" class="text-nowrap">
          <div class="d-flex justify-content-start">
            <button v-if="props.row.invoiceStatus == 0 || props.row.invoiceStatus == 3" class="btn btn-primary mr-1" @click="showEditModal(props.row.id)">
              <feather-icon icon="EditIcon" size="16" />
            </button>
            <b-button variant="success" :disabled="invoiceDocloading[props.row.id]" class="mr-1" @click="downloadInvoiceDocument(props.row.id)">
              <feather-icon v-if="!invoiceDocloading[props.row.id]" icon="PrinterIcon" size="16" />
              <b-spinner v-else small></b-spinner>
            </b-button>
            <!-- disabeld when invoice.status === 1 -->
            <button class="btn btn-info mr-1" v-if="props.row.invoiceStatus == 0 || props.row.invoiceStatus == 3" @click="reviewInvoice(props.row.id)">
              <feather-icon icon="EyeIcon" size="16" />
            </button>
            <!-- copy invoice button -->
            <button class="btn btn-warning mr-1" @click="copyInvoice(props.row.id)">
              <feather-icon icon="CopyIcon" size="16" />
            </button>
            <DeleteModel
              v-if="props.row.invoiceStatus == 0 || props.row.invoiceStatus == 3"
              :modalContent="'هل انت متاكد من حذف فاتورة رقم '"
              :displayName="props.row.invoiceNumber"
              @confirm-delete="handleDelete(props.row.id)"
            />
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select v-model="pageSize" :options="['3', '7', '10', '15', '20']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModel from '@core/components/DeleteModel.vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BRow,
  BCol,
  BSkeletonTable,
  BSpinner,
  BFormCheckbox
} from 'bootstrap-vue'
import VueFileUploud from '@/views/Invoices/VueFileUploud.vue'

export default {
  components: {
    BButton,
    BAvatar,
    BBadge,
    BModal,
    BCardCode,
    DeleteModel,
    VBModal,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    VueGoodTable,
    BRow,
    BCol,
    vSelect,
    BSkeletonTable,
    BSpinner,
    VueFileUploud,
    BFormCheckbox
  },
  data() {
    return {
      watermark: false,
      loading: true,
      invoiceDocloading: {},
      isEditMode: false,
      pageSize: 20,
      pageNumber: 1,
      invoiceNumber: '',
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
      endDate: new Date().toISOString(),

      selectedCompany: null,

      dir: false,
      columns: [
        {
          label: 'رقم الفاتورة',
          field: 'invoiceNumber',
          sortable: true
        },
        {
          label: 'الشركة',
          field: 'companyName',
          sortable: true
        },
        {
          label: 'التاريخ',
          field: 'date',
          sortable: true
        },
        {
          label: 'ضريبة 10%',
          field: 'invoiceTax10Total',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'ضريبة 14%',
          field: 'invoiceTax14Total',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'ضريبة النقل',
          field: 'invoiceTaxTransport',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'مجموع الاشعار',
          field: 'noticeItemsTotal',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'مجموع الفاتورة',
          field: 'invoiceItemsTotal',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'مجموع فاتورة بدون ضريبة',
          field: 'unTaxInvoiceItemsTotal',
          sortable: true,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'الحالة',
          field: 'invoiceStatus',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'التفاصيل',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        }
      ]
    }
  },
  async mounted() {
    await this.fetchCompanies()
    await this.getInvoicesFromGrid()
    this.loading = false
  },

  methods: {
    ...mapActions('invoices', ['fetchInvoicesGrid', 'deleteInvoice', 'openInvoiceDocument', 'fetchCompanies']),

    //make openInvoiceDocument with spinner
    async downloadInvoiceDocument(id) {
      this.$set(this.invoiceDocloading, id, true)

      await this.openInvoiceDocument({ id, watermark: this.watermark })
        .then(() => {
          this.$set(this.invoiceDocloading, id, false)
        })
        .catch(() => {
          this.$set(this.invoiceDocloading, id, false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء فتح الفاتورة',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EGP' }).format(value)
    },
    //format date
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    async getInvoicesFromGrid() {
      const model = {
        StartDate: this.startDate ? new Date(this.startDate) : null,
        EndDate: this.endDate ? new Date(this.endDate) : null,
        Page: this.pageNumber,
        PageSize: this.pageSize,
        CompanyId: this.selectedCompany ? this.selectedCompany.id : null,
        InvoiceNumber: this.invoiceNumber
      }
      await this.fetchInvoicesGrid(model)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تحميل البيانات بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء تحميل البيانات',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },

    showAddModal() {
      this.$router.push({ name: 'add-invoice' })
    },
    showEditModal(id) {
      this.$router.push({ name: 'edit-invoice', params: { id } })
    },
    reviewInvoice(id) {
      this.$router.push({ name: 'review-invoice', params: { id } })
    },

    async handlePageChange() {
      await this.getInvoicesFromGrid()
    },

    async onPerPageChange(newPerPage) {
      this.pageSize = parseInt(newPerPage, 10)
      await this.getInvoicesFromGrid()
    },
    async copyInvoice(id) {
      await this.$router.push({ name: 'copy-invoice', params: { id } })
    },

    async handleDelete(id) {
      await this.deleteInvoice(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'TrashIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء الحذف',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },
    badgeVariant(formattedRow) {
      switch (formattedRow) {
        case 1:
          return 'warning'
        case 2:
          return 'success'
        case 3:
          return 'danger'
        default:
          return 'secondary'
      }
    },
    badgeText(formattedRow) {
      switch (formattedRow) {
        case 1:
          return 'تم المراجعة'
        case 2:
          return 'تم التصديق'
        case 3:
          return 'تم الرفض'
        default:
          return 'لم تتم المراجعة'
      }
    }
  },
  computed: {
    ...mapGetters('invoices', {
      invoices: 'getInvoices',
      totalCount: 'totalCountData',
      pagesCount: 'pagesCount',
      companies: 'getCompanies'
    }),

    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style scoped>
::v-deep .dropdown-menu {
  width: 270px;
}

::v-deep .vs__dropdown-toggle {
  padding: 0.5rem;
}
::v-deep .dropdown-menu {
  width: 270px;
}
::v-deep [dir] .vs__dropdown-toggle {
  padding: 0 0 4px;
  background: none;
  border-radius: 4px;
  padding: 0.4rem;
}
::v-deep .vs__search {
  color: #6e6b7b;
}
::v-deep table.vgt-table td {
  vertical-align: middle;
  color: #6e6b7b;
}
</style>
