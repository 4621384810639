<template>
  <div>
    <div v-if="invoiceLoading" class="d-flex justify-content-center align-items-center w-100" style="height: 65vh">
      <b-spinner style="width: 5rem; height: 5rem" variant="primary" label="Large Spinner"></b-spinner>
    </div>
    <div v-else class="mb-4">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitInvoice)">
          <b-card-code>
            <b-row>
              <b-col md="3">
                <b-row class="mb-2">
                  <b-col>
                    <ValidationProvider name="الشركة" rules="required" v-slot="{ errors }">
                      <v-select
                        v-model="selectedCompany"
                        :options="companies"
                        :getOptionLabel="(comp) => comp.userDisplayName"
                        :class="{ 'is-invalid': errors.length > 0 }"
                        :state="errors.length === 0 ? null : false"
                        dir="rtl"
                        :disabled="isReview"
                        placeholder="اختر الشركة"
                      ></v-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row class="margin-md">
                  <b-col>
                    <ValidationProvider name="رقم الشهادة" rules="required" v-slot="{ errors }">
                      <b-input-group size="lg" prepend="رقم الشهادة">
                        <b-form-input :disabled="isReview" v-model="invoice.certificateNumber" :state="errors.length === 0 ? null : false"></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="3">
                <CompanyAddress v-if="selectedCompany" :address="selectedCompany.address" />
              </b-col>
              <b-col md="3">
                <b-row class="mb-2">
                  <b-col>
                    <ValidationProvider name="رقم الفاتورة" rules="required" v-slot="{ errors }">
                      <b-input-group size="lg" prepend="رقم الفاتورة">
                        <b-form-input :disabled="isReview" v-model="invoice.invoiceNumber" :state="errors.length === 0 ? null : false"></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <ValidationProvider name="التاريخ" rules="required" v-slot="{ errors }">
                      <b-input-group size="lg" prepend="التاريخ">
                        <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="ar"
                          size="lg"
                          v-model="invoice.date"
                          :disabled="isReview"
                          :state="errors.length === 0 ? null : false"
                          placeholder=""
                        ></b-form-datepicker>
                      </b-input-group>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="3">
                <b-img center v-bind="mainProps" src="../../assets/images/logo/vuexy-logo.png" rounded alt="Rounded image"></b-img>
              </b-col>
            </b-row>
          </b-card-code>
          <b-row align-v="center">
            <b-col md="6">
              <b-card-code>
                <span class="btn btn-primary" style="margin-top: -60px; float: right">معلومات عن الشحنة</span>
                <b-row>
                  <b-col md="6">
                    <ValidationProvider name="البوليصة الكلية" rules="required" v-slot="{ errors }">
                      <b-form-group label="البوليصة الكلية">
                        <b-form-input :disabled="isReview" v-model="invoice.totalPolicy" :state="errors.length === 0 ? null : false"></b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="فاتورة رقم">
                      <b-form-input :disabled="isReview" nullable v-model="invoice.supplierInvoice"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- make label input for name of parcelsName -->
                  <b-col md="3">
                    <!-- validation -->
                    <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
                      <b-form-group label="الاسم">
                        <b-form-input :disabled="isReview" v-model="invoice.parcelsName" :state="errors.length === 0 ? null : false"></b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col md="3">
                    <ValidationProvider name="العدد" rules="required|numeric|min_value:1" v-slot="{ errors }">
                      <b-form-group :label="invoice.parcelsName">
                        <b-form-input
                          :disabled="isReview"
                          type="number"
                          v-model="invoice.parcelsNumber"
                          :state="errors.length === 0 ? null : false"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="3">
                    <ValidationProvider name="الوزن" rules="required|min_value:0.01" v-slot="{ errors }">
                      <b-form-group label="الوزن">
                        <b-form-input :disabled="isReview" v-model="invoice.weight" step="0.01" :state="errors.length === 0 ? null : false"></b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="وحدة الوزن">
                      <b-form-select
                        :disabled="isReview"
                        v-model="invoice.weightUnits"
                        :options="[
                          { text: 'كيلو', value: 0 },
                          { text: 'طن', value: 1 },
                          { text: 'ليبرا', value: 2 }
                        ]"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="المشمول">
                      <b-form-input :disabled="isReview" v-model="invoice.covered"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- classification -->
                  <b-col md="3">
                    <b-form-group label="الصنف">
                      <b-form-input :disabled="isReview" v-model="invoice.classification"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="النوع">
                      <b-form-select
                        :disabled="isReview"
                        v-model="invoice.isSub"
                        :options="[
                          { text: 'محجوزات', value: false },
                          { text: 'فرعية', value: true }
                        ]"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group :label="invoice.isSub ? 'فرعية' : 'محجوزات'">
                      <b-form-input type="text" :disabled="isReview" v-model="invoice.reservation"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="اسم المورد">
                      <b-form-input type="text" :disabled="isReview" v-model="invoice.supplierName"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="امر التوريد">
                      <b-form-input type="text" :disabled="isReview" v-model="invoice.purchaseOrder"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-code>
            </b-col>
            <b-col md="6" class="flex-fill">
              <b-card>
                <b-row align-v="center" style="padding: 2rem 0">
                  <b-col>
                    <show-total-avatar class="mb-1" :total="all10Tax" name="اجمالي ضريبة ال 10%" icon="PieChartIcon" color="success" />
                    <show-total-avatar class="mb-1" :total="all14Tax" name="اجمالي ضريبة ال 14%" icon="TrendingUpIcon" color="info" />
                    <show-total-avatar class="mb-1" :total="transportTax" name="ضريبة نقل" icon="TrendingDownIcon" color="warning" />
                    <show-total-avatar :total="unTaxInvoiceTotal" name="فاتورة بدون ضرائب" icon="DollarSignIcon" />
                  </b-col>
                  <b-col style="padding-bottom: 2.75rem; padding-top: 2.75rem">
                    <statistic-card-vertical
                      style="min-width: 100px; font-size: 20px; border-radius: 40%"
                      icon="DollarSignIcon"
                      color="danger"
                      :statistic="formateNoticeTotal"
                      statistic-title="اجمالي الاشعار"
                    />
                  </b-col>

                  <b-col style="padding-bottom: 2.75rem; padding-top: 2.75rem">
                    <statistic-card-vertical
                      style="min-width: 100px; font-size: 20px; border-radius: 40%"
                      icon="DollarSignIcon"
                      color="success"
                      :statistic="formateTotal"
                      statistic-title="اجمالي الفاتورة"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-tabs v-model="tabIndex" justified content-class="mt-3">
            <b-tab v-if="isNoticeTabVisible">
              <template #title>
                <BIconX variant="danger" @click.prevent="removeNoticeTab" font-scale="0.9" style="margin-bottom: 0.6rem" class="border-danger rounded-circle" />
                <BIconExclamationCircleFill v-if="errorEmptyNotice" variant="danger" font-scale="0.9" />
                اشعار
              </template>
              <b-row>
                <b-col v-if="isDone" md="12">
                  <notice-item
                    :type="1"
                    :notice-items="invoice.noticeItems"
                    :invoice-number="invoice.invoiceNumber"
                    :isReview="isReview"
                    @get-total="handleNoticeItemTotal"
                    @update-notice-items="handleNoticeItemsUpdate"
                  />
                </b-col>
                <b-col>
                  <b-card-code>
                    <span class="btn btn-primary" style="margin-top: -60px; float: right">المرفقات</span>
                    <b-form-textarea
                      class="mb-1"
                      rows="4"
                      max-rows="4"
                      id="textarea-large"
                      size="lg"
                      v-model="invoice.details"
                      :disabled="isReview"
                    ></b-form-textarea>
                  </b-card-code>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab v-if="isInvoiceTabVisible">
              <template #title>
                <BIconX
                  variant="danger"
                  @click.prevent="removeInvoiceTab"
                  font-scale="0.9"
                  style="margin-bottom: 0.6rem"
                  class="border-danger rounded-circle"
                />
                <BIconExclamationCircleFill v-if="errorEmptyInvoice" variant="danger" font-scale="0.9" />
                فاتورة
              </template>
              <b-row>
                <b-col v-if="isDone" md="12">
                  <InvoiceItem
                    :invoiceNumber="invoice.invoiceNumber"
                    :isEdit="isEdit"
                    :invoiceItems="invoice.invoiceItems"
                    @get-totals="handleInvoiceItemTotals"
                    :isReview="isReview"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab v-if="isUnTaxInvoiceVisible">
              <template #title>
                <BIconX
                  variant="danger"
                  @click.prevent="removeUnTaxInvoiceTab"
                  font-scale="0.9"
                  style="margin-bottom: 0.6rem"
                  class="border-danger rounded-circle"
                />
                فاتورة بدون ضريبة
              </template>
              <b-row>
                <b-col v-if="isDone" md="12">
                  <notice-item
                    :type="2"
                    :isReview="isReview"
                    :isEdit="isEdit"
                    :invoice-number="invoice.invoiceNumber"
                    :notice-items="invoice.unTaxInvoiceItems"
                    @get-total="handleUnTaxInvoiceItemTotal"
                    @update-notice-items="handleUnTaxInvoiceItemsUpdate"
                  />
                </b-col>
              </b-row>
            </b-tab>

            <b-tab v-if="isSupplementTabVisible" title="ملحق">
              <template #title>
                <BIconX
                  variant="danger"
                  @click.prevent="removeSupplementInvoiceTab"
                  font-scale="0.9"
                  style="margin-bottom: 0.6rem"
                  class="border-danger rounded-circle"
                />
                ملحق
              </template>
              <supplement-invoice
                :isReview="isReview"
                :invoiceNumber="invoice.invoiceNumber"
                :initial-supplement-invoice="invoice.supplementInvoice"
                @update-supplement="handleSupplementUpdate"
              />
            </b-tab>
            <template v-if="!isSupplementTabVisible || !isNoticeTabVisible || !isInvoiceTabVisible || !isUnTaxInvoiceVisible" #tabs-end>
              <b-nav-item v-if="!isNoticeTabVisible" role="presentation" @click.prevent="addNoticeTab" href="#"><b>+ اضافة اشعار</b></b-nav-item>
              <b-nav-item v-if="!isInvoiceTabVisible" role="presentation" @click.prevent="addInvoiceTab" href="#"><b>+ اضافة فاتورة</b></b-nav-item>
              <b-nav-item v-if="!isUnTaxInvoiceVisible" role="presentation" @click.prevent="addUnTaxInvoiceTab" href="#"
                ><b>+ اضافة فاتورة بدون صريبة</b></b-nav-item
              >
              <b-nav-item v-if="!isSupplementTabVisible" role="presentation" @click.prevent="addSupplementInvoiceTab" href="#"><b>+ اضافة ملحق</b></b-nav-item>
            </template>
          </b-tabs>
          <b-row>
            <b-col md="12" class="custom-col d-flex justify-content-between">
              <div>
                <b-button :disabled="isValidating" v-if="!isReview" type="submit" class="btn btn-success mr-1">{{ isEdit ? 'تحديث' : 'اضافة' }}</b-button>
                <!-- button for review  -->
                <b-button :disabled="isValidating" v-if="isReview" @click="changeStatus" class="btn btn-success mr-1">تم</b-button>
                <b-button :disabled="isValidating" class="btn btn-danger" @click="back">الغاء</b-button>
              </div>
              <div>
                <b-button-group>
                  <b-button @click="previse">السابق</b-button>
                  <b-button @click="nextTab">التالي</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required, min_value, numeric } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ShowTotalAvatar from './showTotalAvatar.vue'
import CompanyAddress from './CompanyAddress.vue'
import vSelect from 'vue-select'
import InvoiceItem from './InvoiceItem.vue'
import NoticeItem from './noticeItem.vue'
import SupplementInvoice from './supplementInvoice.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticVer.vue'

import { mapGetters, mapActions } from 'vuex'
import {
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BButton,
  BContainer,
  BRow,
  BCol,
  BInputGroup,
  BFormTextarea,
  BTabs,
  BTab,
  BImg,
  BButtonGroup,
  BFormInvalidFeedback,
  BIcon,
  BIconExclamationCircleFill,
  BAvatar,
  BCard,
  BSpinner,
  BNavItem,
  BIconX,
  BDropdown,
  BDropdownItem,
  BFormSelect
} from 'bootstrap-vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BButton,
    BContainer,
    BRow,
    BCol,
    BInputGroup,
    BCardCode,
    vSelect,
    BFormTextarea,
    InvoiceItem,
    BTabs,
    BTab,
    BImg,
    BButtonGroup,
    CompanyAddress,
    BFormInvalidFeedback,
    NoticeItem,
    BIcon,
    BIconExclamationCircleFill,
    SupplementInvoice,
    BAvatar,
    BCard,
    ShowTotalAvatar,
    StatisticCardVertical,
    BSpinner,
    BNavItem,
    BIconX,
    BDropdown,
    BDropdownItem,
    BFormSelect
  },
  data() {
    return {
      isValidating: false,
      isSupplementTabVisible: true,
      isNoticeTabVisible: true,
      isUnTaxInvoiceVisible: true,
      isInvoiceTabVisible: true,
      tabIndex: 0,
      mainProps: { height: 130, class: 'm1' },
      invoice: {
        invoiceNumber: '',
        date: null,
        total: 0,
        subTotal: 0,
        paymentStatus: 0,
        details: null,
        certificateNumber: '',
        parcelsName: 'عدد الطرود',
        parcelsNumber: 0,
        weight: null,
        weightUnits: 0,
        covered: null,
        classification: null,
        supplierInvoice: null,
        supplierName: null,
        subsidiaryPolicy: null,
        totalPolicy: '',
        purchaseOrder: null,
        reservation: null,
        isSub: false,
        companyId: '',
        invoiceItems: [],
        noticeItems: [],
        unTaxInvoiceItems: [],
        supplementInvoice: null
      },

      alltotal: 0,
      allSubTotal: 0,
      all14Tax: 0,
      all10Tax: 0,
      transportTax: 0,

      noticeTotal: 0,
      unTaxInvoiceTotal: 0,

      selectedCompany: null,
      isEdit: false,
      isReview: false,
      isDone: false,
      errorEmptyInvoice: false,
      errorEmptyNotice: false,

      invoiceLoading: false
    }
  },
  async mounted() {
    this.invoiceLoading = true
    await this.fetchProducts()
    await this.fetchCompanies()
    localize('ar')

    if (this.$route.name === 'review-invoice') {
      this.isReview = true
    } else if (this.$route.name === 'edit-invoice') {
      this.isEdit = true
    }

    const invoiceId = this.$route.params.id
    if (invoiceId) {
      await this.fetchInvoice(invoiceId)
      this.invoice = this.getInvoice
      if (!this.invoice.supplementInvoice) {
        this.isSupplementTabVisible = false
      }
      if (this.invoice.noticeItems.length === 0) {
        this.isNoticeTabVisible = false
      }
      if (this.invoice.unTaxInvoiceItems.length === 0) {
        this.isUnTaxInvoiceVisible = false
      }
      if (this.invoice.invoiceItems.length === 0) {
        this.isInvoiceTabVisible = false
      }

      this.selectedCompany = this.companies.find((comp) => comp.id === this.getInvoice.companyId)

      if (this.$route.name === 'copy-invoice') {
        this.invoice.invoiceItems = this.invoice.invoiceItems.map(({ invoiceItemId, ...rest }) => rest)
        this.invoice.noticeItems = this.invoice.noticeItems.map(({ noticeItemId, ...rest }) => rest)
        this.invoice.unTaxInvoiceItems = this.invoice.unTaxInvoiceItems.map(({ unTaxInvoiceItemId, ...rest }) => rest)
        this.invoice.supplementInvoiceId = 0
        if (this.invoice.supplementInvoice) {
          this.invoice.supplementInvoice.supplementInvoiceId = 0
        }
        this.invoice.id = 0
        this.invoice.invoiceStatus = 0
      }
    }
    this.isDone = true
    this.invoiceLoading = false
  },
  computed: {
    ...mapGetters('invoices', {
      getInvoiceItems: 'getInvoiceItems',
      companies: 'getCompanies',
      getInvoice: 'getInvoice'
    }),
    isInvoiceItemEmpty() {
      return this.getInvoiceItems.length === 0
    },
    isNoticeItemEmpty() {
      return this.invoice.noticeItems.length === 0
    },
    formateTotal() {
      return this.alltotal.toLocaleString('en-Us', { style: 'currency', currency: 'EGP' })
    },
    formateNoticeTotal() {
      return this.noticeTotal.toLocaleString('en-Us', { style: 'currency', currency: 'EGP' })
    }
  },
  methods: {
    ...mapActions('invoices', {
      fetchCompanies: 'fetchCompanies',
      createInvoice: 'createInvoice',
      fetchInvoice: 'fetchInvoice',
      updateInvoice: 'updateInvoice',
      changeInvoiceStatus: 'changeInvoiceStatus'
    }),
    ...mapActions('products', ['fetchProducts']),

    removeSupplementInvoiceTab() {
      this.isSupplementTabVisible = false
      this.supplementInvoice = null
    },
    removeNoticeTab() {
      this.isNoticeTabVisible = false
      this.invoice.noticeItems = []
    },
    removeUnTaxInvoiceTab() {
      this.isUnTaxInvoiceVisible = false
      this.invoice.unTaxInvoiceItems = []
    },
    removeInvoiceTab() {
      this.isInvoiceTabVisible = false
      this.invoice.invoiceItems = []
    },
    addSupplementInvoiceTab() {
      this.isSupplementTabVisible = true
      this.supplementInvoice = null
    },
    addNoticeTab() {
      this.isNoticeTabVisible = true
      this.invoice.noticeItems = []
    },
    addInvoiceTab() {
      this.isInvoiceTabVisible = true
      this.invoice.invoiceItems = []
    },
    addUnTaxInvoiceTab() {
      this.isUnTaxInvoiceVisible = true
      this.invoice.unTaxInvoiceItems = []
    },
    handleNoticeItemsUpdate(newNoticeItems) {
      newNoticeItems = newNoticeItems.map(({ id, ...rest }) => rest)
      this.invoice.noticeItems = newNoticeItems
    },
    //handle UnTaxInvoiceItems
    handleUnTaxInvoiceItemsUpdate(newUnTaxInvoiceItems) {
      newUnTaxInvoiceItems = newUnTaxInvoiceItems.map(({ id, ...rest }) => rest)
      this.invoice.unTaxInvoiceItems = newUnTaxInvoiceItems
    },
    handleSupplementUpdate(newSupplementInvoice) {
      this.invoice.supplementInvoice = newSupplementInvoice
    },
    handleInvoiceItemTotals({ alltotal, allSubTotal, all14Tax, all10Tax, transportTax }) {
      this.alltotal = alltotal
      this.allSubTotal = allSubTotal
      this.all14Tax = all14Tax
      this.all10Tax = all10Tax
      this.transportTax = transportTax
    },
    handleNoticeItemTotal({ noticeTotal }) {
      this.noticeTotal = noticeTotal
    },
    handleUnTaxInvoiceItemTotal({ noticeTotal }) {
      this.unTaxInvoiceTotal = noticeTotal
    },
    previse() {
      this.tabIndex = this.tabIndex - 1
    },
    nextTab() {
      this.tabIndex = this.tabIndex + 1
    },
    async changeStatus() {
      this.isValidating = true

      await this.changeInvoiceStatus(this.invoice.id)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'تم التنفيذ بنجاح',
          icon: 'CheckIcon',
          variant: 'success'
        }
      })
      this.isValidating = false

      this.$router.push({ name: 'invoices' })
    },
    async submitInvoice() {
      this.$refs.observer.validate().then(async (success) => {
        this.isValidating = true

        if (success) {
          this.invoice.companyId = this.selectedCompany.id
          // this.invoice.noticeItems = this.getNoticeItems
          this.invoice.invoiceItems = this.getInvoiceItems

          if (!this.isSupplementTabVisible) {
            this.invoice.supplementInvoice = null
            this.invoice.supplementInvoiceId = null
          }
          if (!this.isNoticeTabVisible) {
            this.invoice.noticeItems = []
          }
          if (!this.isUnTaxInvoiceVisible) {
            this.invoice.unTaxInvoiceItems = []
          }
          if (!this.isInvoiceTabVisible) {
            this.invoice.invoiceItems = []
          }
          // check ther must be one of invoiceItems , unTaxInvoiceItems and noticeItems must be filled
          if (this.invoice.invoiceItems.length === 0 && this.invoice.noticeItems.length === 0 && this.invoice.unTaxInvoiceItems.length === 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'يجب اضافة عناصر الفاتورة او الاشعار',
                icon: 'TrashIcon',
                variant: 'danger'
              }
            })
            this.isValidating = false
            return
          }
          //notice validation
          if (this.isNoticeItemEmpty && this.isNoticeTabVisible) {
            this.tabIndex = 0
            this.errorEmptyNotice = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'يجب اضافة اشعار الفاتورة',
                icon: 'TrashIcon',
                variant: 'danger'
              }
            })
            this.isValidating = false
            return
          }
          this.errorEmptyNotice = false

          if (this.isInvoiceItemEmpty && this.isInvoiceTabVisible) {
            this.errorEmptyInvoice = true
            this.tabIndex = 1
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'يجب اضافة عناصر الفاتورة',
                icon: 'TrashIcon',
                variant: 'danger'
              }
            })
            this.isValidating = false
            return
          }
          this.errorEmptyInvoice = false

          if (this.invoice.supplierInvoice === '') {
            this.invoice.supplierInvoice = null
          }
          if (this.invoice.reservation === '') {
            this.invoice.reservation = null
          }

          // this.invoice.invoiceItems.forEach((item, index) => {
          //   item.sortOrder = index + 1
          // })

          // this.invoice.noticeItems.forEach((item, index) => {
          //   item.sortOrder = index + 1
          // })

          // this.invoice.unTaxInvoiceItems.forEach((item, index) => {
          //   item.sortOrder = index + 1
          // })
          // console.log(this.invoice)

          try {
            if (this.isEdit) {
              await this.updateInvoice(this.invoice)
            } else {
              await this.createInvoice(this.invoice)
            }
          } catch (error) {
            this.isValidating = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'خطأ في السيرفر',
                icon: 'TrashIcon',
                variant: 'danger'
              }
            })
            return
          }

          this.resetForm()
          this.tabIndex = 0
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم التنفيذ بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$router.push({ name: 'invoices' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'خطأ في السيرفر',
              icon: 'TrashIcon',
              variant: 'danger'
            }
          })
        }
        this.isValidating = false
      })
    },

    resetForm() {
      this.$refs.observer.reset()
      this.invoice = {
        Id: 0,
        InvoiceNumber: '',
        Date: null,
        Total: 0,
        SubTotal: 0,
        PaymentStatus: 0,
        Details: '',
        CertificateNumber: '',
        parcelsNumber: null,
        Weight: null,
        Covered: '',
        SupplierInvoice: null,
        SupplierName: null,
        SubsidiaryPolicy: null,
        PurchaseOrder: 0,
        totalPolicy: '',
        Reservation: null,
        CompanyId: '',
        InvoiceItems: []
      }
      this.selectedCompany = null
    },
    back() {
      this.resetForm()
      this.$router.push({ name: 'invoices' })
    }
  }
}
</script>

<style scoped>
::v-deep .col-form-label {
  font-size: 1.4rem;
  line-height: 1.45;
}
::v-deep .card .card-title {
  font-weight: 500;
  font-size: 1.5rem;
}
::v-deep .nav-tabs .nav-item {
  font-size: 1.5rem;
  position: relative;
}

::v-deep .vs__dropdown-toggle {
  padding: 0.5rem;
}
::v-deep .dropdown-menu {
  width: 270px;
}
::v-deep [dir] .vs__dropdown-toggle {
  padding: 0 0 4px;
  background: none;
  border-radius: 4px;
  padding: 0.4rem;
}
/* search */
::v-deep .vs__search {
  color: #6e6b7b;
}
@media (max-width: 768px) {
  .margin-md {
    margin-bottom: 1.5rem;
  }
}
</style>
