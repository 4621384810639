<template>
  <b-card>
    <div class="table-container">
      <h2 class="mb-2 text-center">
        فاتورة رقم <b-badge variant="primary">{{ invoiceNumber }}</b-badge>
      </h2>
      <!-- <span style="margin-top: -70px; float: right" class="btn btn-primary">فتورة رقم {{ invoiceNumber }}</span> -->
      <b-skeleton-table v-if="!optionsFetched" :rows="5" :columns="4" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <BEditableTable
        v-else="optionsFetched"
        disableDefaultEdit
        :rowUpdate="rowUpdate"
        :editMode="'row'"
        class="editable-table"
        v-model="localInvoiceItems"
        :fields="[...fields]"
        :sortBy="'sortOrder'"
        :sortDesc="false"
        borderless
        responsive
      >
        <!-- index -->
        <template #cell(index)="data">
          <div>{{ data.index + 1 }}</div>
        </template>

        <template #cell(subTotal)="data">
          {{ formatSubTotal(data.item) }}
        </template>

        <template #cell(total)="data">
          {{ formatTotal(data.item) }}
        </template>

        <!-- price -->
        <template #cell(price)="data">
          <div>{{ formatCurrency(data.item.price) }}</div>
        </template>

        <template #custom-foot="data">
          <b-tr v-if="!isReview">
            <b-td class="d-flex align-items-center plus-icon rounded-circle"> <BIconPlus variant="success" @click="handleAdd()"></BIconPlus></b-td>
            <b-td colspan="9"></b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="5"></b-td>
            <b-td style="font-size: 1.5rem; font-weight: bold"> ضريبة ال 10% </b-td>
            <b-td style="font-size: 1.3rem">
              <div class="font-weight-bold">{{ formatCurrency(all10Tax) }}</div>
            </b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="5"></b-td>
            <b-td style="font-size: 1.5rem; font-weight: bold"> ضريبة ال 14% </b-td>
            <b-td style="font-size: 1.3rem">
              <div class="font-weight-bold">{{ formatCurrency(all14Tax) }}</div>
            </b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="5"></b-td>
            <b-td style="font-size: 1.5rem; font-weight: bold">ضريبة نقل</b-td>
            <b-td style="font-size: 1.3rem">
              <div class="font-weight-bold">{{ formatCurrency(transportTax) }}</div>
            </b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="5"></b-td>
            <b-td style="font-size: 1.5rem; font-weight: bold"> المجموع الفرعي </b-td>
            <b-td style="font-size: 1.3rem">
              <div class="font-weight-bold">{{ formatCurrency(allSubTotal) }}</div>
            </b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="5"></b-td>
            <b-td style="font-size: 1.5rem; font-weight: bold"> المجموع الكلي </b-td>
            <b-td style="font-size: 1.3rem">
              <div class="font-weight-bold">{{ formatCurrency(alltotal) }}</div>
            </b-td>
            <b-td></b-td>
          </b-tr>
        </template>

        <template #cell(edit)="data">
          <div v-if="!isReview" class="text-center">
            <div v-if="data.isEdit">
              <BIconX class="edit-icon" @click="handleSubmit(data, false)"></BIconX>
              <BIconCheck class="edit-icon" @click="handleSubmit(data, true)"></BIconCheck>
            </div>
            <div v-else>
              <b-button class="rounded-circle p-1 mr-1" variant="outline-primary" @click="handleEdit(data, true)">
                <BIconPencil></BIconPencil>
              </b-button>
              <b-button class="rounded-circle p-1 mr-1" variant="outline-danger" @click="handleDelete(data)">
                <BIconTrash></BIconTrash>
              </b-button>
              <BIconArrowUp v-if="data.index !== 0" class="edit-icon" @click="moveUp(data.index)"></BIconArrowUp>
              <BIconArrowDown v-if="data.index !== localInvoiceItems.length - 1" class="edit-icon" @click="moveDown(data.index)"></BIconArrowDown>
            </div>
          </div>
        </template>
      </BEditableTable>
    </div>
  </b-card>
</template>

<script>
import BEditableTable from './EditGrid.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BIconArrowUp,
  BIconArrowDown,
  BIconPlusCircle,
  BIconPlus,
  BTh,
  BTr,
  BTd,
  BSkeletonTable,
  BCard,
  BBadge
} from 'bootstrap-vue'

export default {
  components: {
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BIconArrowUp,
    BIconArrowDown,
    BIconPlus,
    BTh,
    BTr,
    BTd,
    BCardCode,
    BSkeletonTable,
    BCard,
    BBadge
  },
  data() {
    return {
      optionsFetched: false,
      alltotal: 0,
      allSubTotal: 0,
      all14Tax: 0,
      all10Tax: 0,
      transportTax: 0,
      fields: [
        {
          key: 'index',
          label: '#',
          thStyle: 'font-size: 1.5rem; width: 60px;',
          isRowHeader: true
        },
        {
          key: 'productId',
          label: 'الاسم',
          type: 'vue-select',
          selectLabel: 'name',
          selectId: 'id',
          thStyle: 'font-size: 1.5rem; width: 200px;',
          editable: true,
          validate: this.validateProduct,
          placeholder: 'ادخل المنتج'
        },
        {
          key: 'price',
          label: 'السعر',
          thStyle: 'font-size: 1.5rem; width: 100px;',
          type: 'number',
          editable: true,
          placeholder: 'ادخل السعر',
          validate: this.validatePrice
        },
        {
          key: 'quantity',
          label: 'الكمية',
          thStyle: 'font-size: 1.5rem; width: 50px;',
          type: 'number',
          editable: true,
          placeholder: 'ادخل الكمية',
          validate: this.validateQuantity
        },
        {
          key: 'taxId',
          label: 'الضريبة',
          type: 'vue-select',
          selectLabel: 'name',
          selectId: 'id',
          class: 'tax-col',
          thStyle: 'font-size: 1.5rem; width: 200px;',
          editable: true,
          placeholder: 'ادخل الضريبة'
        },
        {
          key: 'subTotal',
          label: 'المجموع الفرعي',
          thStyle: 'font-size: 1.5rem; width: 160px;',
          editable: false
        },
        {
          key: 'total',
          label: 'الاجمالي',
          thStyle: 'font-size: 1.5rem; width: 160px;',
          editable: false
        },
        { key: 'edit', label: '', thStyle: 'width: 200px;' }
      ],
      rowUpdate: {},
      localInvoiceItems: []
    }
  },
  props: {
    invoiceItems: {
      type: Array,
      required: false,
      default: () => []
    },
    invoiceNumber: {
      type: String,
      required: false,
      default: '0000'
    },
    isReview: {
      type: Boolean
    },
    isEdit: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters('products', ['getProducts']),
    ...mapGetters('invoices', ['getTaxes']),
    // isEditRow indicate if the row in edit mode
    isEditRow() {
      return this.localInvoiceItems.some((item) => item.id === this.rowUpdate.id)
    }
  },
  async mounted() {
    // set localInvoiceItems to the invoiceItems prop and add prop id to each item to be used in the editable table
    this.localInvoiceItems = this.invoiceItems.map((item, index) => ({ ...item, id: index }))
    await this.fetchTaxes()

    this.fields.find((field) => field.key === 'productId').options = this.getProducts
    this.fields.find((field) => field.key === 'taxId').options = this.getTaxes
    this.$nextTick(() => {
      this.calcAllTotal()
    })
    this.optionsFetched = true
  },
  watch: {
    localInvoiceItems: {
      handler(newVal) {
        this.setInvoiceItems(newVal)
        this.calcAllTotal()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('invoices', ['fetchTaxes', 'addInvoiceItem', 'updateInvoiceItem', 'deleteInvoiceItem', 'setInvoiceItems', 'deleteInvoiceItems']),

    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EGP' }).format(value)
    },

    calcAllTotal() {
      this.alltotal = 0
      this.allSubTotal = 0
      this.all14Tax = 0
      this.all10Tax = 0
      this.transportTax = 0
      this.localInvoiceItems.forEach((item) => {
        // make sure that there is only one item with product id 1
        const product1Items = this.localInvoiceItems.filter((item) => item.productId === 1)
        if (product1Items.length > 1) {
          const lastItem = product1Items[product1Items.length - 1]
          this.localInvoiceItems = this.localInvoiceItems.filter((item) => item.id !== lastItem.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'لا يمكن اضافة اكثر من منتج نقل',
              text: 'يجب ان يكون هناك منتج نقل واحد فقط',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        }
        if (item.productId === 1) {
          item.taxId = 2
          this.transportTax = item.price * item.quantity * 0.14
        }
        const tax = this.getTaxes.find((tax) => tax.id === item.taxId)
        item.subTotal = item.price * item.quantity
        item.total = tax ? item.subTotal + item.subTotal * tax.value : item.subTotal
        this.allSubTotal += item.subTotal
        this.alltotal += item.total
        if (tax && tax.value === 0.14) {
          this.all14Tax += item.subTotal * tax.value
        } else if (tax && tax.value === 0.1) {
          this.all10Tax += item.subTotal * tax.value
        }
      })
      this.all14Tax = this.all14Tax - this.transportTax
      this.$emit('get-totals', {
        alltotal: this.alltotal,
        allSubTotal: this.allSubTotal,
        all14Tax: this.all14Tax,
        all10Tax: this.all10Tax,
        transportTax: this.transportTax
      })
    },

    formatSubTotal(item) {
      return this.formatCurrency(item.price * item.quantity)
    },
    formatTotal(item) {
      const tax = this.getTaxes.find((tax) => tax.id === item.taxId)
      const total = tax ? item.subTotal + item.subTotal * tax.value : item.subTotal
      return this.formatCurrency(total)
    },

    moveUp(index) {
      if (index === 0) return // Cannot move up the first item

      // Swap items in the array
      const movedItem = this.localInvoiceItems[index]
      const previousItem = this.localInvoiceItems[index - 1]

      // Update sortOrder
      movedItem.sortOrder = previousItem.sortOrder
      previousItem.sortOrder = movedItem.sortOrder + 1

      // Reorder the array
      this.localInvoiceItems = [...this.localInvoiceItems.slice(0, index - 1), movedItem, previousItem, ...this.localInvoiceItems.slice(index + 1)]
    },

    moveDown(index) {
      if (index === this.localInvoiceItems.length - 1) return // Cannot move down the last item

      // Swap items in the array
      const movedItem = this.localInvoiceItems[index]
      const nextItem = this.localInvoiceItems[index + 1]

      // Update sortOrder
      movedItem.sortOrder = nextItem.sortOrder
      nextItem.sortOrder = movedItem.sortOrder - 1

      // Reorder the array
      this.localInvoiceItems = [...this.localInvoiceItems.slice(0, index), nextItem, movedItem, ...this.localInvoiceItems.slice(index + 2)]
    },

    handleAdd() {
      // do not add if there is an empty row or an edit row in rowUpdate make toaster to notify the user
      if (this.localInvoiceItems.some((item) => item.id === undefined) || this.rowUpdate.edit) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'لا يمكن اضافة صف جديد',
            text: 'يجب اكمال الصفوف الفارغة او حفظ التعديلات الحالية',
            icon: 'XCircleIcon',
            variant: 'danger'
          }
        })
        return
      }

      const newId = Date.now()
      this.rowUpdate = {
        edit: true,
        id: newId,
        action: 'add',
        addPosition: 'end',
        // The default add position is at the top of the list. Use the below prop to insert a new row to the very end.
        data: {
          id: newId,
          productId: this.getProducts[1].id,
          price: 1,
          quantity: 1,
          description: '',
          tax: null,
          taxId: null,
          total: 0,
          subTotal: 0,
          sortOrder: this.localInvoiceItems.reduce((max, item) => (item.sortOrder > max ? item.sortOrder : max), 0) + 1
        }
      }
    },
    handleSubmit(data, update) {
      this.rowUpdate = {
        edit: false,
        id: data.id,
        action: update ? 'update' : 'cancel'
      }
    },
    handleEdit(data) {
      this.rowUpdate = { edit: true, id: data.id }
    },
    handleDelete(data) {
      this.rowUpdate = { id: data.id, action: 'delete' }
      // Reassign sortOrder for remaining items
      this.localInvoiceItems = this.localInvoiceItems.filter((item) => item.id !== data.id).map((item, index) => ({ ...item, sortOrder: index + 1 }))
    },
    validateProduct(value) {
      if (value === '' || value === null) {
        return {
          valid: false,
          errorMessage: 'المنتج مطلوب'
        }
      }
      return { valid: true }
    },
    validatePrice(value) {
      if (value === '' || value == '0' || value === null) {
        return {
          valid: false,
          errorMessage: 'السعر مطلوب'
        }
      }
      return { valid: true }
    },
    validateQuantity(value) {
      if (value === '' || value == '0' || value === null) {
        return {
          valid: false,
          errorMessage: 'الكمية مطلوبة'
        }
      }
      return { valid: true }
    }
  }
}
</script>

<style>
.table-container {
  margin: 10px;
}
[dir] .table {
  margin-bottom: 0rem;
}
[dir] .table th,
[dir] .table td {
  padding: 0.5rem;
}

table.editable-table {
  margin-top: 10px;
}

table.editable-table td {
  vertical-align: middle;
}
[dir] .table th,
[dir] .table td {
  text-align: right;
  padding: 0.5rem;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.remove-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.edit-icon {
  color: rgb(4, 83, 158);
  cursor: pointer;
  font-size: 2rem;
}
.plus-icon {
  cursor: pointer;
  font-size: 3rem;
}

.name-col {
  width: 200px;
}

.department-col {
  width: 150px;
}
.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.total-col {
  text-align: center;
}

.reorder-col {
  width: 60px;
}

.is-active-col {
  width: 100px;
}

@media (max-width: 768px) {
  .editable-table .table {
    font-size: 14px; /* Increase font size */
    width: 1000px;
  }
}
</style>
